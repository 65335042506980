import React, {useEffect, useState} from 'react';
import {
    Card,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Input,
  } from "@material-tailwind/react";
  import { NavbarDark} from "../Inc/nav";
  import {  DefaultSidebar } from "../Inc/SideNav";
  import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    PencilIcon,
    TrashIcon,
    PlusIcon,
    
  } from "@heroicons/react/solid";
  import  Modal  from './Modal';
  import axios from "axios";
  import EditModal from "./EditModal";
  const TABLE_HEAD = [
    "Produit",
    "Unite",
    "quantite",
    "Actions",
    
  ];
  
  const TABLE_ROWS = [
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
     
    },
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
      
    },
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
     
    },
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
      
    },
   
  ];

const Show = () => {
    const [showModal, setShowModal] = useState(false);
   const [sorties,setSorties] = useState([]);
   const [showEditModal, setShowEditModal] = useState(false);
   const [editItem, setEditItem] = useState(null);
   const [EditInput, setEditProduct] = useState({
     produit: "",
     unite: "",
     quantite: "",
   
   });
  useEffect(() => {
    fetchSorties();
  })
  const fetchSorties = async () => {
    axios 
    .get("http://localhost:8000/api/sees")
    .then((response) => {
      setSorties(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  }
   const openModal = () => {
    setShowModal(true);
  };
  const handleEditClick = (id) => {
    // Set the selected fournisseur ID
    const editingEntre = sorties.find(
      (sortie) => sortie.id === id
    );
    console.log(editingEntre); 
    setEditItem(id);
    setEditProduct({
      nom: editingEntre.nom,
    unite: editingEntre.unite,
    quantite: editingEntre.quantite,
    prix_Unitaire: editingEntre.prix_Unitaire,
    photo_Produits: editingEntre.photo_Produits,
    fournisseur: editingEntre.fournisseur,
    categorie: editingEntre.categorie,
    entrepots: editingEntre.entrepots,
    date_create: editingEntre.date_create,
    date_exp: editingEntre,
    });
    setShowEditModal(true);
  };
  const handleEdit = (e) => {
    e.preventDefault();

    const formData = new FormData();
   
    formData.append("produit", EditInput.produit);
    formData.append("unite",EditInput.unite);
    formData.append("quantite",EditInput.quantite);
   
    axios
      .put(`http://localhost:8000/api/ed/${editItem}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          fetchSorties();
          setShowEditModal(false);
        } else {
          //
        }
      })
      .catch((error) => {
        console.error("An error occured", error);
      });
  };
  return (
    <div>
        < NavbarDark />
       <div className="flex"> 
    < DefaultSidebar /> 
    <Card className="w-full lg:w-[calc(100vw-10rem)] p-4">
      <div className="flex items-center justify-between mb-4">
        <div className="relative flex gap-2">
          <Input
            type="search"
            color="dark"
            label="Type here ..."
            className="pr-20"
            containerProps={{
              className: "min-w-[288px]",
            }}
          />
        <Button size="sm" color="blue" className="rounded">
  Filter
</Button>

        </div>
        
        <Modal openModal={openModal} />
      </div>
      <div className="overflow-x-auto">
      <table className="w-full min-w-max table-auto text-left  bg-white">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-10 bg-blue-gray-50 p-2"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-50"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sorties.map(
            (sortie,index ) => (
              <tr key={index} className={ index % 2 === 0 ?  "even:bg-blue-gray-50/50" : ""}>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {sortie.produit}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {sortie.unite}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {sortie.quantite}
                  </Typography>
                </td>
               
           <td className="p-4">
  <div className="flex items-center">
    <Typography as="button" variant="small" color="blue-gray" className="font-medium" onClick={() => handleEditClick(sortie.id)}>
      <PencilIcon className="h-4 w-4 text-green-500" /> {/* Set color to green */}
    </Typography>

    <Typography as="button" variant="small" color="blue-gray" className="font-medium ml-2">
      <TrashIcon className="h-4 w-4 text-red-500" /> {/* Set color to red */}
    </Typography>
  </div>
  <div>
    
  {showEditModal && ( <EditModal
  product={EditInput} // Pass the EditInput data to the modal
  editItem={editItem}
  showEditModal={showEditModal}
 
  fetchSorties={fetchSorties}
 
   handelFormSubmit={handleEdit}
  onclose={() => {
    setShowEditModal(false);
    setEditItem(null);
    setEditProduct({
    produit: "",
    unite: "",
    quantite: "",
   
    })
    
  }}
/>
)}
  </div>
</td>


               
               
              </tr>
            )
          )}
        </tbody>
      </table>
      <div className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page 1 of 10
        </Typography>
        <div className="flex gap-2">
          <Button variant="outlined" size="sm">
            <ArrowNarrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button variant="outlined" size="sm">
            <ArrowNarrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
      </div>
    </Card>
    </div>
    </div>
  )
}

export default Show
