import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {
    Card,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Input,
  } from "@material-tailwind/react";
  import { NavbarDark} from "../Inc/nav";
  import {  DefaultSidebar } from "../Inc/SideNav";
  import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    PencilIcon,
    TrashIcon,
    PlusIcon,
  } from "@heroicons/react/solid";
 

  const TABLE_HEAD = [
    "Nom",
    "code",
    "PU",
    "Unité",
    "categories",
    "Entrepots",
    "Fournisseur",
    "Status",
    "date d'expiration",
    "date creation",
    "Actions",
  ];
  
  const TABLE_ROWS = [
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
     
    },
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
      
    },
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
     
    },
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
      
    },
   
  ];
const Audit = () => {

  const [productInfo, setProductInfo] = useState([]);
const [UserName, setUserName] = useState([]);
  useEffect(() => {
    // Fetch user's name from cookies
    const userId = Cookies.get('id_user'); // Assuming 'id_user' is the cookie name

    // Fetch the user's name using a separate API endpoint
    fetch(`http://localhost:8000/api/user/${userId}`)
        .then(response => response.json())
        .then(data => setUserName(data.name))
        .catch(error => console.error('Error fetching user name: ', error));

        fetch('http://localhost:8000/api/audit')
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProductInfo(data);
        })
        .catch((error) => {
          console.error('Error fetching product information: ', error);
        });
}, []);
  return (
    
      <div>
      < NavbarDark />
       <div className="flex"> 
    < DefaultSidebar /> 
    <Card className="w-full lg:w-[calc(100vw-10rem)] p-4">
      <div className="flex items-center justify-between mb-4">
        <div className="relative flex gap-2">
          <Input
            type="search"
            color="dark"
            label="Type here ..."
            className="pr-20"
            containerProps={{
              className: "min-w-[288px]",
            }}
          />
        <Button size="sm" color="blue" className="rounded">
  Filter
</Button>

        </div>
        
        
      </div>
      <div className="overflow-x-auto">
      <table className="w-full min-w-max table-auto text-left  bg-white">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-10 bg-blue-gray-50 p-2"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-50"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {productInfo.map(
            (
              product,
              index
            ) => (
              <tr key={index} className={ index % 2 === 0 ? "even:bg-blue-gray-50/50" : ""}>
              <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
               {UserName}
                  </Typography>
                </td>
                
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                   {product.product_name}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                 {product.quantity_entered}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                 {product.quantity_out}
                  </Typography>
                </td>
                


               
               
              </tr>
            )
          )}
        </tbody>
      </table>
      <div className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page 1 of 10
        </Typography>
        <div className="flex gap-2">
          <Button variant="outlined" size="sm">
            <ArrowNarrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button variant="outlined" size="sm">
            <ArrowNarrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
      </div>
    </Card>
    </div>
    </div>
  )
}

export default Audit
