import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
  Card,
  Typography,
  Button,
 
  Input,
} from "@material-tailwind/react";
import { NavbarDark} from "../Inc/nav";
import {  DefaultSidebar } from "../Inc/SideNav";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  PencilIcon,
  TrashIcon,

} from "@heroicons/react/solid";
import AjoutEntrepot from "./AjoutEntrepot";
import EditEntrepots from "./EditEntrepots";

const TABLE_HEAD = [
    "Nom",
    "Adresse",
    "Actions",
  ];
  
  
const Entrepot = () => {

  const [showModal, setShowModal] = useState(false); 
  const [entrepots, setEntrepots] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  
  const [EditInput, setEditInput] = useState({
    nom:"",
    adresse:""
  })

  useEffect(() =>{
    fetchEntrepots();
  }, []);
  const fetchEntrepots = async () => {
    axios.get('http://localhost:8000/api/show')
    .then(response => {
      setEntrepots(response.data);
    })
    .catch(error => {
      console.error(error);
    });
  }
  const handleDeleteClick = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Fournis?"
    );
    if (confirmDelete) {
      axios
        .delete(`http://localhost:8000/api/suprimer/${id}`)
        .then((response) => {
          fetchEntrepots();
        })
        .catch((error) => {
          console.error("Error deleting Fournis", error);
        });
    }
  };
  const openEditModal = (id) => {
    const editingEntre = entrepots.find((entrepot) => entrepot.id ===id);
    console.log(editingEntre);
    setEditItem(id);
    setEditInput({
      nom: editingEntre.nom,
      adresse: editingEntre.adresse,
    });
    setShowEditModal(true);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const handleEdit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nom", EditInput.nom);
   
    formData.append("adresse", EditInput.adresse);

    axios
      .put(`http://localhost:8000/api/editer/${editItem}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          fetchEntrepots();
          setShowEditModal(false);
        } else {
          //
        }
      })
      .catch((error) => {
        console.error("An error occured", error);
      });
  };

 
  return (
    <div>
           <NavbarDark />
  <div className="flex"> 
< DefaultSidebar /> 
<Card className="w-full lg:w-[calc(100vw-10rem)] p-4">
 <div className="flex items-center justify-between mb-4">
   <div className="relative flex gap-2">
     <Input
       type="search"
       color="dark"
       label="Type here ..."
       className="pr-20"
       containerProps={{
         className: "min-w-[288px]",
       }}
     />
   <Button size="sm" color="blue" className="rounded">
Filter
</Button>

   </div>
   <AjoutEntrepot openModal={openModal} fetchEntrepots={fetchEntrepots} />
   
 </div>
 <div className="overflow-x-auto">
 <table className="w-full min-w-max table-auto text-left  bg-white">
   <thead>
     <tr>
       {TABLE_HEAD.map((head) => (
         <th
           key={head}
           className="border-b border-blue-gray-10 bg-blue-gray-50 p-2"
         >
           <Typography
             variant="small"
             color="blue-gray"
             className="font-normal leading-none opacity-50"
           >
             {head}
           </Typography>
         </th>
       ))}
     </tr>
   </thead>
   <tbody>
     {entrepots.map((entrepot,index) => 
       (
         <tr key={index} className={ index % 2 === 0 ? "even:bg-blue-gray-50/50" : ""}>
           <td className="p-4">
             <Typography
               variant="small"
               color="blue-gray"
               className="font-normal"
             >
             {entrepot.nom}
             </Typography>
           </td>
           <td className="p-4">
             <Typography
               variant="small"
               color="blue-gray"
               className="font-normal"
             >
             {entrepot.adresse}
             </Typography>
           </td>
     
      <td className="p-4">
<div className="flex items-center">
<Typography
                      as="button"
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                      onClick={() => openEditModal(entrepot.id)}
                    >
                      <PencilIcon className="h-4 w-4 text-green-500" />
                    </Typography>

<Typography as="button" variant="small" color="blue-gray" className="font-medium ml-2" onClick={()=>handleDeleteClick(entrepot.id)}>
 <TrashIcon className="h-4 w-4 text-red-500" /> {/* Set color to red */}
</Typography>
</div>
</td>
{showEditModal && (
  <EditEntrepots
   product={EditInput}
    editItem={editItem}
    showEditModal={showEditModal}
    handelForm={handleEdit}
    fetchEntrepots={fetchEntrepots}
    onClose={() => {
      setShowEditModal(false);
      setEditItem(null);
      setEditInput({
      nom:"",
      adresse:""
    })
    }}
  />
)}

          
          
         </tr>
       )
     )}
   </tbody>
 </table>


 <div className="flex items-center justify-between border-t border-blue-gray-50 p-4">
   <Typography variant="small" color="blue-gray" className="font-normal">
     Page 1 of 10
   </Typography>
   <div className="flex gap-2">
     <Button variant="outlined" size="sm">
       <ArrowNarrowLeftIcon className="h-4 w-4" />
     </Button>
     <Button variant="outlined" size="sm">
       <ArrowNarrowRightIcon className="h-4 w-4" />
     </Button>
   </div>
 </div>
 </div>
</Card>
</div>
    </div>
  )
}

export default Entrepot
