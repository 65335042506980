import React, { useState } from "react";
import { Select, Option, Card } from "@material-tailwind/react";

function Choix() {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleProduct = (productName) => {
    if (selectedProducts.includes(productName)) {
      setSelectedProducts(
        selectedProducts.filter((name) => name !== productName)
      );
    } else {
      setSelectedProducts([...selectedProducts, productName]);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Replace the product names and add any additional products as needed
  const products = [
    "Product 1",
    "Product 2",
    "Product 3",
    "Product 4",
    "Product 5",
  ];

  const handleAddProduct = () => {
    // Handle displaying the selected products here
    console.log("Selected Products:", selectedProducts);
  };

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Card className="w-96 p-5">
        <div className="relative  justify-between">
         <div className="float-right p-1">
          <button
            className="btn btn-primary"
            onClick={handleAddProduct}
          >
            Ajouter
          </button>
          </div>
          <br></br>
          <div className="p-5">
          <Select
            size="lg" // Use "lg" size to make it larger
            label="Select Products"
            onClick={toggleDropdown}
            open={isDropdownOpen}
          >
            {products.map((productName) => (
              <Option key={productName}>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={selectedProducts.includes(productName)}
                    onChange={() => toggleProduct(productName)}
                    className="h-5 w-5"
                  />
                  {productName}
                </div>
              </Option>
            ))}
          </Select>
          </div>
        </div>
        <div className="mt-2">
          <Card>
            <div className="p-4">
              <h2>Selected Products:</h2>
              <ul>
                {selectedProducts.map((product) => (
                  <li key={product}>{product}</li>
                ))}
              </ul>
            </div>
          </Card>
        </div>
      </Card>
    </div>
  );
}

export default Choix;
