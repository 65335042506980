import React, { useState } from "react";
import { Select, Option, Input } from "@material-tailwind/react";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import axios from "axios";


const EditEntrepots = ({handleForm, showEditModal ,onClose, product,editItem,fetchEntrepots ,validationErrors }) => {
  
  const [EditProduct, setEditProduct] = useState({
    nom: product.nom,
    adresse : product.adresse,
  });
  const refreshForm = () => {
      setEditProduct({
        nom:"",
        adresse:""
  });
  }
  const handleUpdate = () => {
    const formData  = new FormData();
    formData.append("nom", EditProduct.nom);
    formData.append("adresse", EditProduct.adresse);
    console.log("the data", formData);
    axios
     .put(`http://localhost:8000/api/editer/${editItem}`, EditProduct)
     .then((response) => {
      console.log(response.data);
      fetchEntrepots ();
      refreshForm();
      onClose();
     })
     .catch((error) =>{
      console.error(error);
     });
  };
  return (
   
    <div>
     <TEModal show={showEditModal} setShow={onClose}>
          <TEModalDialog>
            <TEModalContent>
            <form onSubmit={handleForm}>
              <TEModalHeader className="bg-dark">
                {/* <!--Modal title--> */}
                <h5 className="text-xl  text-white font-medium items-center leading-normal text-neutral-800 white:text-neutral-200">
                  Ajout
                </h5>
                {/* <!--Close button--> */}
                <button
                  type="button"
                  className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  onClick={onClose}
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </TEModalHeader>
              {/* <!--Modal body--> */}
              <TEModalBody  >
              
                  <div className="flex w-70 flex-col gap-6 p-2">
                    <TERipple rippleColor=""></TERipple>
                    <div className="my-0  items-center ">
                      <Input color="blue" label="nom"  value={EditProduct.nom}
                      onChange={(e) => {
                        e.preventDefault();
                        setEditProduct((EditProduct) => ({
                          ...EditProduct,
                          nom: e.target.value,
                        }));
                      }}/>
                    </div>
                    <div className="my-0  items-center ">
                      <Input color="blue" label="adresse"   value={EditProduct.adresse}
                      onChange={(e) => {
                        e.preventDefault();
                        setEditProduct((EditProduct) => ({
                          ...EditProduct,
                          adresse: e.target.value,
                        }));
                      }}/>
                    </div>
                    {/* <!--Subscribe newsletter checkbox--> */}
                  </div>
                  {/* <!--Submit button--> */}
               
              </TEModalBody>
              <TEModalFooter className="bg-dark">
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    className="inline-block rounded bg-danger text-white  px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 float-left"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </TERipple>
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  
                  onClick={handleUpdate}>
                    Save changes
                  </button>
                </TERipple>
              </TEModalFooter>
              </form>
            </TEModalContent>
          </TEModalDialog>
        </TEModal>  
    </div>
  );
};


export default EditEntrepots;
