import React, { useState } from "react";
import { Select, Option, Input } from "@material-tailwind/react";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import { PlusIcon } from "@heroicons/react/solid";
import axios from "axios";
const AjoutEntrepot = ({ fetchEntrepots })=> {
  const [showModal, setShowModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [Product, setProduct] = useState({
    nom: "",
    adresse: "",
  });
  const refreshForm = () => {
    setProduct({
      nom: "",
      adresse: "",
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};
    if (!Product.nom) {
      errors.nom = "Le Nom est requis";
    }

    if (!Product.adresse) {
      errors.adresse = "Le Adresse est requis";
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});

    const formData = new FormData();
    formData.append("nom", Product.nom);
    formData.append("adresse", Product.adresse);
    console.log("the data", formData);
    axios
      .post("http://localhost:8000/api/ajouter", formData)
      .then((res) => {
        console.log("Response from server:", res);
        // ... rest of your code ...
        setValidationErrors({});
        fetchEntrepots();
        setShowModal(false);
        refreshForm();
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
       
     
  };
  return (
    <div>
      <TERipple rippleColor="white">
        <button
          type="button"
          className="inline-block  flex rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          onClick={() => setShowModal(true)}
        >
          <PlusIcon className="h-4 w-4 mr-2" />
          Ajouter
        </button>
      </TERipple>

      {/* <!-- Modal --> */}
      <TEModal show={showModal} setShow={setShowModal}>
        <TEModalDialog>
          <TEModalContent>
            <form onSubmit={handleSubmit}>
              <TEModalHeader  className="bg-dark">
                {/* <!--Modal title--> */}
                <h5 className="text-xl font-medium justify-center leading-normal text-neutral-800 text-white white:text-neutral-200">
                  Ajout
                </h5>
                {/* <!--Close button--> */}
                <button
                  type="button"
                  className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </TEModalHeader>
              {/* <!--Modal body--> */}
              <TEModalBody>
                <div className="flex w-70 flex-col gap-6 p-2">
                  <TERipple rippleColor=""></TERipple>
                  <div className="my-0 flex items-center gap-4">
                    <Input
                      color="blue"
                      label="nom"
                      value={Product.nom}
                      onChange={(e) =>
                        setProduct({ ...Product, nom: e.target.value })
                      }
                    />
                  </div>
                  <div className="my-0  items-center ">
                    <Input
                      color="blue"
                      label="adresse"
                      value={Product.adresse}
                      onChange={(e) =>
                        setProduct({ ...Product, adresse: e.target.value })
                      }
                    />
                  </div>
                </div>
                {/* <!--Submit button--> */}
              </TEModalBody>
              <TEModalFooter className="bg-dark">
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    className="inline-block text-white rounded bg-danger px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 float-left"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </TERipple>
                <TERipple rippleColor="light">
                  <button
                    type="submit"
                    className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    Save changes
                  </button>
                </TERipple>
              </TEModalFooter>
            </form>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </div>
  );
};

export default AjoutEntrepot;