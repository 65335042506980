import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Input,
} from "@material-tailwind/react";
import { NavbarDark} from "../Inc/nav";
import {  DefaultSidebar } from "../Inc/SideNav";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  PencilIcon,
  TrashIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import Addcat from "./Addcat";
import EditCat from "./EditCat";
import axios from "axios";

const TABLE_HEAD = [
  "Labelle",
  "Actions",
];

const TABLE_ROWS = [
  {
   Labelle: "John Michael",
   
   
  },
  {
    Labelle: "John Michael",
   
    
  },
  {
    Labelle: "John Michael",
   
   
  },
  {
    Labelle: "John Michael",
   
    
  },
 
];

const Category = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [categories, setCategories]  = useState ([]);
  const [EditInput, setEditInput] = useState({
    libelle:"",
  
  })
  const openModal = () => {
    setShowModal(true);
  };
  const openEditModal = (id) => {
    const editingCat = categories.find((categorie) => categorie.id === id);
    console.log(editingCat);
    setEditItem(id);
    setEditInput({
      libelle: editingCat.libelle,
    })
    setShowEditModal(true);
  };
  useEffect(()=> {
    fetchCategories();
  }, []);
  const fetchCategories = async () => {
    axios.get('http://localhost:8000/api/voir')
    .then(response => {
      setCategories(response.data);
      
    })
    .catch(error => {
      console.error(error);
    })
  }
    const handleEdit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("libelle", EditInput.libelle);
   

    axios
      .put(`http://localhost:8000/api/edit/${editItem}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          fetchCategories();
          setShowEditModal(false);
        } else {
          //
        }
      })
      .catch((error) => {
        console.error("An error occured", error);
      });
  };

  const handleDeleteClick = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Fournis?"
    );
    if (confirmDelete) {
      axios
        .delete(`http://localhost:8000/api/suprime/${id}`)
        .then((response) => {
          fetchCategories();
        })
        .catch((error) => {
          console.error("Error deleting Fournis", error);
        });
    }
  };
  return (
  <div >
  < NavbarDark />
  <div className="flex"> 
< DefaultSidebar /> 
<Card className="w-full lg:w-[calc(100vw-10rem)] p-4">
 <div className="flex items-center justify-between mb-4">
   <div className="relative flex gap-2">
     <Input
       type="search"
       color="dark"
       label="Type here ..."
       className="pr-20"
       containerProps={{
         className: "min-w-[288px]",
       }}
     />
   <Button size="sm" color="blue" className="rounded">
Filter
</Button>

   </div>
   <Addcat openModal={openModal} fetchCategories={fetchCategories} />

 </div>
 <div className="overflow-x-auto">
 <table className="w-full min-w-max table-auto text-left  bg-white">
   <thead>
     <tr>
       {TABLE_HEAD.map((head) => (
         <th
           key={head}
           className="border-b border-blue-gray-10 bg-blue-gray-50 p-2"
         >
           <Typography
             variant="small"
             color="blue-gray"
             className="font-normal leading-none opacity-50"
           >
             {head}
           </Typography>
         </th>
       ))}
     </tr>
   </thead>
   <tbody>
     {categories.map(
       (categorie,index) => (
         <tr key={index} className= { index % 2 === 0 ? "even:bg-blue-gray-50/50" : ""}>
           <td className="p-4">
             <Typography
               variant="small"
               color="blue-gray"
               className="font-normal"
             >
               {categorie.libelle}
             </Typography>
           </td>
     
      <td className="p-4">
<div className="flex items-center">
<Typography
                      as="button"
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                      onClick={() => openEditModal(categorie.id)}
                    >
                      <PencilIcon className="h-4 w-4 text-green-500" />
                    </Typography>

<Typography as="button" variant="small" color="blue-gray" className="font-medium ml-2" onClick={() => handleDeleteClick(categorie.id)}>
 <TrashIcon className="h-4 w-4 text-red-500" /> {/* Set color to red */}
</Typography>
</div>
</td>
         </tr>
       )
     )}
   </tbody>
 </table>
 {showEditModal && (
  <EditCat
  product={EditInput}
    editItem={editItem}
    showEditModal={showEditModal}
    handleForm={handleEdit}
    fetchCategories={fetchCategories}
    onClose={() => {
      setShowEditModal(false);
      setEditItem(null);
      setEditInput({
        libelle:"",
    })
    }}
  />
)}

 <div className="flex items-center justify-between border-t border-blue-gray-50 p-4">
   <Typography variant="small" color="blue-gray" className="font-normal">
     Page 1 of 10
   </Typography>
   <div className="flex gap-2">
     <Button variant="outlined" size="sm">
       <ArrowNarrowLeftIcon className="h-4 w-4" />
     </Button>
     <Button variant="outlined" size="sm">
       <ArrowNarrowRightIcon className="h-4 w-4" />
     </Button>
   </div>
 </div>
 </div>
</Card>
</div>
</div>
);
}

export default Category
