import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select, Option, Input } from "@material-tailwind/react";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import { PlusIcon } from "@heroicons/react/solid";

const EditModal = ({
  product, showEditModal,onclose,editItem,handleFormSubmit,fetchEntres,
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [selectedFournisseur, setSelectedFournisseur] = useState("");
  const [selectedEntrepots, setSelectedEntrepots] = useState("");
  const [selectedUnite, setSelectedUnite] = useState("");
  const [entrepots, setEntrepots] = useState([]);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [unites, setUnites] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pricture, setPicture] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [EditProduct, setEditProduct] = useState({
    nom: product.nom,
    unite: product.unite,
    quantite: product.quantite,
    prix_Unitaire: product.prix_Unitaire,
    photo_Produits: product.photo_Produits,
    fournisseur:product.fournisseur,
    categorie:product.categorie,
    entrepots:product.entrepots,
    date_create: product.date_create,
    date_exp: product.date_exp,
  });
  const refreshForm = () => {
    setEditProduct({
      nom: "",
      unite: "",
      quantite: "",
      prix_Unitaire: "",
      photo_Produits: "",
      fournisseur: "",
      categorie: "",
      entrepots: "",
      date_create: "",
      date_exp: "",
    });
  };
 
  const [selectedDate, setSelectedDate] = useState(""); // Initialize with an empty string

  // Handle changes to the selected date
  const handleDateChange = (event) => {
    setSelectedDate({
      ...selectedDate,
      [event.target.name]: event.target.value,
    });
  };
  const handleImage = (e) => {
    setPicture({ photo_Produits: e.target.files[0] });
  };

  useEffect(() => {
    fetchUnites(); // Fetch menus when the component mounts
    fetchEntrepots();
    fetchFournisseurs();
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("http://localhost:8000/api/voir"); // Replace with your API endpoint for fetching categories
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchUnites = async () => {
    try {
      const response = await axios.get("http://localhost:8000/api/see"); // Replace with your API endpoint for fetching categories
      setUnites(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchEntrepots = async () => {
    try {
      const response = await axios.get("http://localhost:8000/api/show"); // Replace with your API endpoint for fetching categories
      setEntrepots(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchFournisseurs = async () => {
    try {
      const response = await axios.get("http://localhost:8000/api/index"); // Replace with your API endpoint for fetching categories
      setFournisseurs(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const triggerScanner = () => {
    // Connect to the WebSocket server
    const socket = new WebSocket("ws://192.168.1.121:3000");

    // Send the "openScanner" message
    socket.onopen = () => {
      socket.send("openScanner");
    };

    // Close the socket when done
    socket.onclose = () => {
      socket.close();
    };
  };
  const handleUpdate= (e) => {
   

    const formData = new FormData();
    formData.append("nom", EditProduct.nom);
    formData.append("photo_Produits", EditProduct.photo_Produits);
    formData.append("unite", EditProduct.unite);
    formData.append("quantite", EditProduct.quantite);
    formData.append("prix_Unitaire",EditProduct.prix_Unitaire);
    formData.append("fournisseur",EditProduct.fournisseur);
    formData.append("categorie", EditProduct.fournisseur);
    formData.append("entrepots", EditProduct.entrepots);
    formData.append("date_create", EditProduct.date_create);
    formData.append("date_exp", EditProduct.date_exp);

    axios
    .put(`http://localhost:8000/api/up/${editItem}`, EditProduct)
      .then((res) => {
        if (res.data.status === 200) {
          console.log("Success");
          setValidationErrors({});
          refreshForm();
        } else {
          console.log("Server returned an error:", res.data.message);
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <TERipple rippleColor="white">
        <button
          type="button"
          className="inline-block  flex rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          onClick={() => setShowModal(true)}
        >
          <PlusIcon className="h-4 w-4 mr-2" />
          Ajouter
        </button>
      </TERipple>

      {/* <!-- Modal --> */}
      <TEModal show={showEditModal} setShow={onclose}>
        <TEModalDialog>
          <TEModalContent>
            <form onSubmit={handleFormSubmit}>
              <TEModalHeader>
                {/* <!--Modal title--> */}
                <h5 className="text-xl font-medium items-center leading-normal text-neutral-800 dark:text-neutral-200">
                  Ajout Article
                </h5>
                {/* <!--Close button--> */}
                <button
                  type="button"
                  className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </TEModalHeader>
              {/* <!--Modal body--> */}
              <TEModalBody>
                <form>
                  <div className="flex w-70 flex-col gap-6 p-2">
                    <TERipple rippleColor="">
                      <button
                        type="button"
                        className="inline-block rounded bg-primary px-4 pb-1 pt-2 text-white font-medium uppercase leading-normal text-white-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                        onClick={triggerScanner}
                      >
                        <i className="fa fa-eye text-base mr-2" />
                      </button>
                    </TERipple>
                    <div className="my-0 flex items-center gap-4">
                      <div class="relative h-11 w-full min-w-[200px]">
                        <input
                          name="nom"
                          type="text"
                          className="peer h-full w-full border-b border-black-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-black-700 outline outline-0 transition-all placeholder-shown:border-black-200 focus:border-black-500 focus:outline-0 disabled:border-0 disabled:bg-black-50"
                          onChange={(e) => setEditProduct({ ...EditProduct, nom: e.target.value })}
                          value={EditProduct.nom}
                        />
                        {validationErrors.nom && (
                          <div className="text-danger">
                            {validationErrors.nom}
                          </div>
                        )}
                        <label class="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-blue-grey-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-blue-grey-500 peer-focus:after:scale-x-100 peer-focus:after:border-blue-grey-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Nom
                        </label>
                      </div>
                      <div class="relative h-11 w-full min-w-[200px]">
                        <input
                          type="file"
                          className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-blue-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          placeholder=" "
                          onChange={handleImage}
                        />
                        <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-blue-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-blue-gray-500 peer-focus:after:scale-x-100 peer-focus:after:border-blue-gray-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Image
                        </label>
                      </div>
                    </div>
                    <div className="my-0 flex items-center gap-4">
                      <Select
                        name="unite"
                        onChange={(value) => setEditProduct({ ...EditProduct, unite: value })}
  value={EditProduct.unite}
                        variant="standard"
                        label="Unité"
                      >
                        {unites.map((unite) => (
                          <Option key={unite.id} value={unite.id}>
                            {unite.libelle}
                          </Option>
                        ))}
                      </Select>
                      {validationErrors.unite && (
                        <div className="text-danger">
                          {validationErrors.unite}
                        </div>
                      )}
                      <div class="relative h-11 w-full min-w-[200px]">
                        <input
                          name="quantite"
                          onChange={(e) => {
    e.preventDefault();
    setEditProduct((EditProduct) => ({
      ...EditProduct,
      quantite: e.target.value,
    }));
                          }}
                          value={EditProduct.quantite}
                          className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          placeholder=" "
                          type="number"
                        />
                        {validationErrors.quantite && (
                          <div className="text-danger">
                            {validationErrors.quantite}
                          </div>
                        )}
                        <label class="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-blue-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-blue-gray-500 peer-focus:after:scale-x-100 peer-focus:after:border-blue-gray-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          quantite
                        </label>
                      </div>
                    </div>
                    <div className="my-0 flex items-center gap-4">
                      <div class="relative h-11 w-full min-w-[200px]">
                        <input
                       onChange={(e) => {
    e.preventDefault();
    setEditProduct((EditProduct) => ({
      ...EditProduct,
      prix_Unitaire: e.target.value,
    }));
                       }}
                          value={EditProduct.prix_Unitaire}
                          className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          placeholder=" "
                          type="text"
                          name="prix_Unitaire"
                        />
                        {validationErrors.prix_Unitaire && (
                          <div className="text-danger">
                            {validationErrors.prix_Unitaire}
                          </div>
                        )}
                        <label class="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-blue-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-blue-gray-500 peer-focus:after:scale-x-100 peer-focus:after:border-blue-gray-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          prix_Unitaire
                        </label>
                      </div>
                      <Select
                        name="fournisseur"
                        onChange={(value) => setSelectedFournisseur(value)}
                        value={EditProduct.fournisseur}
                        variant="standard"
                        label="fournisseur"
                      >
                        {fournisseurs.map((fournisseur) => (
                          <Option key={fournisseur.id} value={fournisseur.id}>
                            {fournisseur.Nom}
                          </Option>
                        ))}
                      </Select>
                      {validationErrors.fournisseur && (
                        <div className="text-danger">
                          {validationErrors.fournisseur}
                        </div>
                      )}
                    </div>
                    <div className="my-0 flex items-center gap-4">
                      <Select
                        name="categorie"
                        onChange={(value) => setEditProduct({ ...EditProduct, categorie: value })}
  value={EditProduct.categorie}
                        variant="standard"
                        label="categorie"
                      >
                        {categories.map((category) => (
                          <Option key={category.id} value={category.id}>
                            {category.libelle}
                          </Option>
                        ))}
                      </Select>
                      {validationErrors.categorie && (
                        <div className="text-danger">
                          {validationErrors.categorie}
                        </div>
                      )}

                      <div className="w-72">
                        <Select
                          name="entrepots"
                          onChange={(value) => setEditProduct({ ...EditProduct, entrepots : value })}
  value={EditProduct.entrepots}
                          variant="standard"
                          label="entrepot"
                        >
                          {entrepots.map((entrepot) => (
                            <Option key={entrepot.id} value={entrepot.id}>
                              {entrepot.nom}
                            </Option>
                          ))}
                        </Select>
                        {validationErrors.entrepots && (
                          <div className="text-danger">
                            {validationErrors.entrepots}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="my-0 flex items-center gap-4">
                    <div class="relative h-11 w-full min-w-[200px]">
                      <input
                        type="date"
                        className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        placeholder=" "
                        name="date_create"
                        value={selectedDate.date_create}
                        onChange={handleDateChange}
                      />
                      <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-blue-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-blue-gray-500 peer-focus:after:scale-x-100 peer-focus:after:border-blue-gray-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        date creation
                      </label>
                    </div>

                    <div className="relative h-11 w-full min-w-[200px]">
                      <input
                        type="date"
                        className="peer h-full w-full border-b border-blue-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-blue-grey-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        placeholder=" "
                        name="date_exp"
                        value={selectedDate.date_exp}
                        onChange={handleDateChange}
                      />
                      <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-blue-grey-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-blue-grey-500 peer-focus:after:scale-x-100 peer-focus:after:border-blue-gray-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        date d'expiration
                      </label>
                    </div>
                  </div>

                  {/* <!--Subscribe newsletter checkbox--> */}
                  <div className="mb-6 flex min-h-[1.5rem] items-center justify-center pl-[1.5rem]">
                    <label
                      className="inline-block pl-[0.15rem] hover:cursor-pointer"
                      htmlFor="exampleCheck25"
                    >
                      Subscribe to our newsletter
                    </label>
                  </div>
                  {/* <!--Submit button--> */}
                </form>
              </TEModalBody>
              <TEModalFooter>
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 float-left"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </TERipple>
                <TERipple rippleColor="light">
                  <button
                    type="submit"
                    className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    onClick={handleUpdate}
                  >
                    Save changes
                  </button>
                </TERipple>
              </TEModalFooter>
            </form>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </div>
  );
};

export default EditModal;
