import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select, Option, Input } from "@material-tailwind/react";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import { PlusIcon } from "@heroicons/react/solid";

const EditModal = ({
  product,
  showEditModal,
  onclose,
  editItem,
  handleFormSubmit,
  fetchSorties,
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [selectedFournisseur, setSelectedFournisseur] = useState("");
  const [selectedEntrepots, setSelectedEntrepots] = useState("");
  const [selectedUnite, setSelectedUnite] = useState("");
  const [entrepots, setEntrepots] = useState([]);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [unites, setUnites] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pricture, setPicture] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [EditProduct, setEditProduct] = useState({
    produit: product.produit,
    unite: product.unite,
    quantite: product.quantite,
  });
  const [entres, setEntres] = useState([]);
  const refreshForm = () => {
    setEditProduct({
      produit: "",
      unite: "",
      quantite: "",
    });
  };

  const [selectedDate, setSelectedDate] = useState(""); // Initialize with an empty string

  // Handle changes to the selected date
  const handleDateChange = (event) => {
    setSelectedDate({
      ...selectedDate,
      [event.target.name]: event.target.value,
    });
  };
  const handleImage = (e) => {
    setPicture({ photo_Produits: e.target.files[0] });
  };

  useEffect(() => {
    fetchUnites(); // Fetch menus when the component mounts
    fetchEntrepots();
    fetchFournisseurs();
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("http://localhost:8000/api/voir"); // Replace with your API endpoint for fetching categories
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchUnites = async () => {
    try {
      const response = await axios.get("http://localhost:8000/api/see"); // Replace with your API endpoint for fetching categories
      setUnites(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchEntrepots = async () => {
    try {
      const response = await axios.get("http://localhost:8000/api/show"); // Replace with your API endpoint for fetching categories
      setEntrepots(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchFournisseurs = async () => {
    try {
      const response = await axios.get("http://localhost:8000/api/index"); // Replace with your API endpoint for fetching categories
      setFournisseurs(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const triggerScanner = () => {
    // Connect to the WebSocket server
    const socket = new WebSocket("ws://192.168.1.121:3000");

    // Send the "openScanner" message
    socket.onopen = () => {
      socket.send("openScanner");
    };

    // Close the socket when done
    socket.onclose = () => {
      socket.close();
    };
  };
  const handleUpdate = (e) => {
    const formData = new FormData();

    formData.append("produits", EditProduct.photo_Produits);
    formData.append("unite", EditProduct.unite);
    formData.append("quantite", EditProduct.quantite);

    axios
      .put(`http://localhost:8000/api/up/${editItem}`, EditProduct)
      .then((res) => {
        if (res.data.status === 200) {
          console.log("Success");
          fetchSorties();
          setValidationErrors({});
          refreshForm();
        } else {
          console.log("Server returned an error:", res.data.message);
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <TERipple rippleColor="white">
        <button
          type="button"
          className="inline-block  flex rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          onClick={() => setShowModal(true)}
        >
          <PlusIcon className="h-4 w-4 mr-2" />
          Ajouter
        </button>
      </TERipple>

      {/* <!-- Modal --> */}
      <TEModal show={showEditModal} setShow={onclose}>
        <TEModalDialog>
          <TEModalContent>
            <form onSubmit={handleFormSubmit}>
              <TEModalHeader>
                {/* <!--Modal title--> */}
                <h5 className="text-xl font-medium items-center leading-normal text-neutral-800 dark:text-neutral-200">
                  Ajout Article
                </h5>
                {/* <!--Close button--> */}
                <button
                  type="button"
                  className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </TEModalHeader>
              {/* <!--Modal body--> */}
              <TEModalBody>
              <div className="my-0 flex items-center gap-4">
                      <Select
                        name="produit"
                        onChange={(e) => {
    e.preventDefault();
    setEditProduct((EditProduct) => ({
      ...EditProduct,
      produit: e.target.value,
    }));
                          }}
                          value={EditProduct.produit}
                        variant="standard"
                        label="produits"
                      >
                        {entres.map((Produit) => (
                          <Option key={Produit.id} value={Produit.id}>
                            {Produit.nom}
                          </Option>
                        ))}
                      </Select>
                      {validationErrors.produit && (
                        <div className="text-danger">
                          {validationErrors.produit}
                        </div>
                      )}
                     
                  </div>
                 
                  <br></br>
                 <div className="my-0 flex items-center gap-4">
                 <div class="relative h-11 w-full min-w-[200px]">
                 <Select
                        name="unite"
                        onChange={(value) => setEditProduct({ ...EditProduct, unite: value })}
  value={EditProduct.unite}
                        variant="standard"
                        label="Unité"
                      >
                        {unites.map((unite) => (
                          <Option key={unite.id} value={unite.id}>
                            {unite.libelle}
                          </Option>
                        ))}
                      </Select>
                      {validationErrors.unite && (
                        <div className="text-danger">
                          {validationErrors.unite}
                        </div>
                      )}
                      </div>
                      <div class="relative h-11 w-full min-w-[200px]">
                        <input
                          name="quantite"
                          onChange={(e) => {
    e.preventDefault();
    setEditProduct((EditProduct) => ({
      ...EditProduct,
      quantite: e.target.value,
    }));
                          }}
                          value={EditProduct.quantite}
                          className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-pink-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          placeholder=" "
                          type="number"
                        />
                        {validationErrors.quantite && (
                          <div className="text-danger">
                            {validationErrors.quantite}
                          </div>
                        )}
                        <label class="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-blue-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-blue-gray-500 peer-focus:after:scale-x-100 peer-focus:after:border-blue-gray-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          quantite
                        </label>
                      </div>
                  </div>
                 
                {/* <!--Submit button--> */}
              
            </TEModalBody>
              <TEModalFooter>
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 float-left"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </TERipple>
                <TERipple rippleColor="light">
                  <button
                    type="submit"
                    className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    onClick={handleUpdate}
                  >
                    Save changes
                  </button>
                </TERipple>
              </TEModalFooter>
            </form>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </div>
  );
};

export default EditModal;
