
import './App.css';
import Login from './Connection/Login.jsx';
import Inscription from './Connection/Inscription';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav from './Inc/nav.jsx';
import SideNav from './Inc/SideNav';
import Entre from './Produits/Entre.jsx';
import Article from './Produits/Article';
import Footer from './Inc/footer';
import Show from './Sortie/Show';
import Modal from './Sortie/Modal';
import Invent from './Inventaire/Invent';
import Fournisseur from './Fournisseur/Fournis';
import Audit from './Historique/Audit';
import AjoutModal from './Historique/AjoutModal';
import Pricing from './Pricing/pricing';
import Choix from './Pricing/choix';
import List from './Market/List';
import Category from './Category/Category';
import Entrepot from './Entrepot/Entrepot';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/Login" element={<Login />}></Route>
          
          <Route path="Inscription" element={<Inscription />}></Route>
           <Route path='nav' element={<Nav /> }></Route>
           <Route path='sideNav' element={<SideNav /> }></Route>
           <Route path='Entre' element={<Entre /> }></Route>
           <Route path= '/ajout' element = {<Article/>}></Route>
           <Route path= '/footer' element = {<Footer/>}></Route>
           <Route path='/sortie' element= {<Show/>}></Route>
           <Route path='/modal' element = {<Modal/>}></Route>
           <Route path='/invent' element = {<Invent />}></Route>
           <Route path='/' element = {<Fournisseur/>}></Route>
           <Route path='/audit' element ={ <Audit />}></Route>
           <Route path='/ajt' element = {<AjoutModal/>}></Route>
           <Route path='/price' element = {<Pricing />}></Route>
           <Route path='/choix' element = {<Choix />}></Route>
           <Route path='/market' element = {<List />}></Route>
           <Route path='/category' element = {<Category />}></Route>
           <Route path='/entrepot' element = {<Entrepot />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
