import React, { useState } from "react";
import { Select, Option, Input } from "@material-tailwind/react";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import { PlusIcon } from "@heroicons/react/solid";
import axios from "axios";

const EditModal = ({
  product,
  showEditModal,
  onclose,
  editItem,
  handelFormSubmit,
  fetchFournisseurs,
  ValidationErrors,
}) => {
  const [EditProduct, setEditProduct] = useState({
    Nom: product.Nom,
    Prenom: product.Prenom,
    Adresse: product.Adresse,
  });
  const refreshForm = () => {
    setEditProduct({
      Nom: "",
      Prenom: "",
      Adresse: "",
    });
  };

  const handleUpdate = (e) => {
    
    const formData = new FormData();
    formData.append("Nom", EditProduct.Nom);
    formData.append("Prenom", EditProduct.Prenom);
    formData.append("Adresse", EditProduct.Adresse);
    console.log("the data", formData);
    console.log("EditProduct:", EditProduct);
    axios
    .put(`http://localhost:8000/api/update/${editItem}`, EditProduct)
    .then((response) => {
      console.log( response.data);
      fetchFournisseurs();
      refreshForm();
      onclose();
    })
    .catch((error) => {
      console.error(error);
    });
  };

  return (
    <div>
      {/* <!-- Modal --> */}
      <TEModal show={showEditModal} setShow={onclose}>
        <TEModalDialog  className="bg-dark">
          <TEModalContent>
            <form onSubmit={ handelFormSubmit}>
              <TEModalHeader className="bg-dark">
                {/* <!--Modal title--> */}
                <h5 className=" text-white text-xl font-medium justify-center leading-normal text-neutral-800 white:text-neutral-200">
                  Ajout Fournisseur
                </h5>
                {/* <!--Close button--> */}
                <button
                  type="button"
                  className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  onClick={() => onclose()}
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </TEModalHeader>
              {/* <!--Modal body--> */}
              <TEModalBody>
                <div className="flex w-70 flex-col gap-6 p-2">
                  <TERipple rippleColor=""></TERipple>
                  <div className="my-0 flex items-center gap-4">
                  <Input
  color="blue"
  label="Nom"
  value={EditProduct.Nom}
  onChange={(e) => {
    e.preventDefault();
    setEditProduct((EditProduct) => ({
      ...EditProduct,
      Nom: e.target.value,
    }));
    console.log("EditProduct after change:", EditProduct);
  }}
/>

                    <Input
                      color="blue"
                      label="Prenom"
                      value={EditProduct.Prenom}
                      onChange={(e) => {
                        e.preventDefault();
                        setEditProduct((EditProduct) => ({
                          ...EditProduct,
                          Prenom: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="my-0  items-center ">
                    <Input
                      color="blue"
                      label="Adresse"
                      autoFocus
                      value={EditProduct.Adresse}
                      onChange={(e) => {
                        e.preventDefault();
                        setEditProduct((EditProduct) => ({
                          ...EditProduct,
                          Adresse: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                {/* <!--Submit button--> */}
              </TEModalBody>
              <TEModalFooter className="bg-dark">
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    className=" text-white inline-block rounded bg-danger px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-danger-accent-100 focus:bg-danger-accent-100 focus:outline-none focus:ring-0 active:bg-danger-accent-200 float-left"
                    onClick={() => onclose()}
                  >
                    Close
                  </button>
                </TERipple>
                <TERipple rippleColor="light">
                  <button
                    type="submit"
                    className="ml-1 inline-block rounded bg-primary  px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    onClick={handleUpdate}
                  >
                    Save changes
                  </button>
                </TERipple>
              </TEModalFooter>
            </form>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </div>
  );
};

export default EditModal;
