// PlanCard.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const PlanCard = ({ title, price, features }) => {
  return (
    <div className="plan-card-wrapper m-1">
        
        <div className="rounded-3xl  border-2 p-4 bg-white shadow-md text-center">
      <h2 className="mb-4  text-xl font-medium border-b-2 border-red-500">{title}</h2>
      <p className="text-lg">{price}</p>
      <ul className='p-4'>
        {features.map((feature, index) => (
       <li key={index}>  
       <FontAwesomeIcon icon={faCheckCircle} className="text-red-500 mr-2" />  {feature}</li>
        ))}
      </ul>
      <div className='p-2'>
      <button className="  btn btn-danger text-red-500"><a href='/choix'>Buy</a></button>
      </div>
    </div>
    </div>
  );
};

export default PlanCard;
