import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Card,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Input,
  } from "@material-tailwind/react";
  import { NavbarDark} from "../Inc/nav";
  import {  DefaultSidebar } from "../Inc/SideNav";
  import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    PencilIcon,
    TrashIcon,
    PlusIcon,
  } from "@heroicons/react/solid";
  import Cookies from 'js-cookie';

  const TABLE_HEAD = [
   "Product Name",
   "Quantite entre",
   "quantite  Sortie",
   "quantite reste",
   "date d'ajout ",
   "date sortie",
  ];
  
  const TABLE_ROWS = [
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
     
    },
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
      
    },
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
     
    },
    {
      Nom: "John Michael",
      code: "Manager",
      PU: "1200",
      Unité: "Litre",
      categories: "piso",
      Entrepots: "B1",
      Fournisseur: "Judi",
      Status: "Valued",
      date_creation: "12/12/26",
      date_expiration: "14/02/23",
      
    },
   
  ];

const Invent = () => {
  const [productInfo, setProductInfo] = useState([]); 
  useEffect(() => {
    // Fetch combined product information from Laravel API
    axios.get("http://localhost:8000/api/info")
      .then(response => {
        setProductInfo(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);
  return (
    <div>
      < NavbarDark />
       <div className="flex"> 
    < DefaultSidebar /> 
    <Card className="w-full lg:w-[calc(100vw-10rem)] p-4">
      <div className="flex items-center justify-between mb-4">
        <div className="relative flex gap-2">
          <Input
            type="search"
            color="dark"
            label="Type here ..."
            className="pr-20"
            containerProps={{
              className: "min-w-[288px]",
            }}
          />
        <Button size="sm" color="blue" className="rounded">
  Filter
</Button>

        </div>
        
        
      </div>
      <div className="overflow-x-auto">
      <table className="w-full min-w-max table-auto text-left  bg-white">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-10 bg-blue-gray-50 p-2"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-50"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {productInfo.map(
            (
              product,
              index
            ) => (
              <tr key={index} className={index % 2 ===0 ? "even:bg-blue-gray-50/50" : ""} style={{ backgroundColor: product.quantity_entered - product.quantity_out < 10 ? "red" : "" }}>
                <td className="p-2">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {product.product_name}
                  </Typography>
                </td>
              
                <td className="p-2">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {product.quantity_entered}
                  </Typography>
                </td>
                <td className="p-2">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                 {product.quantity_out}
                  </Typography>
                </td>
              
                <td className="p-2">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {product.quantity_entered - product.quantity_out}
                  </Typography>
                </td>
                <td className="p-2">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {product.date_entered}
                  </Typography>
                </td>
                <td className="p-2">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                {product.date_out}
                  </Typography>
                </td>
              
        


               
               
              </tr>
            )
          )}
        </tbody>
      </table>
      <div className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page 1 of 10
        </Typography>
        <div className="flex gap-2">
          <Button variant="outlined" size="sm">
            <ArrowNarrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button variant="outlined" size="sm">
            <ArrowNarrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
      </div>
    </Card>
    </div>
    </div>
  )
}

export default Invent
