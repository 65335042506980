import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Input,
} from "@material-tailwind/react";
import { NavbarDark} from "../Inc/nav";
import {  DefaultSidebar } from "../Inc/SideNav";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  PencilIcon,
  TrashIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import Article from "./Article"; 
import EditModal from "./EditModal";

const TABLE_HEAD = [
  "Nom",
  "code",
  "PU",
  "Unité",
  "categories",
  "Entrepots",
  "Fournisseur",
  "Status",
  "date d'expiration",
  "date creation",
  "Actions",
];

const TABLE_ROWS = [
  {
    Nom: "John Michael",
    code: "Manager",
    PU: "1200",
    Unité: "Litre",
    categories: "piso",
    Entrepots: "B1",
    Fournisseur: "Judi",
    Status: "Valued",
    date_creation: "12/12/26",
    date_expiration: "14/02/23",
   
  },
  {
    Nom: "John Michael",
    code: "Manager",
    PU: "1200",
    Unité: "Litre",
    categories: "piso",
    Entrepots: "B1",
    Fournisseur: "Judi",
    Status: "Valued",
    date_creation: "12/12/26",
    date_expiration: "14/02/23",
    
  },
  {
    Nom: "John Michael",
    code: "Manager",
    PU: "1200",
    Unité: "Litre",
    categories: "piso",
    Entrepots: "B1",
    Fournisseur: "Judi",
    Status: "Valued",
    date_creation: "12/12/26",
    date_expiration: "14/02/23",
   
  },
  {
    Nom: "John Michael",
    code: "Manager",
    PU: "1200",
    Unité: "Litre",
    categories: "piso",
    Entrepots: "B1",
    Fournisseur: "Judi",
    Status: "Valued",
    date_creation: "12/12/26",
    date_expiration: "14/02/23",
    
  },
 
];

export function Entre() {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const[entres, setEntre] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [EditInput, setEditProduct] = useState({
    nom: "",
    unite: "",
    quantite: "",
    prix_Unitaire: "",
    photo_Produits: "",
    fournisseur: "",
    categorie: "",
    entrepots: "",
    date_create: "",
    date_exp: "",
  });
  useEffect(() => {
    fetchEntres();
  }, []);
  const openModal = () => {
    setShowModal(true);
  };
  const fetchEntres = async () => {
    axios
      .get("http://localhost:8000/api/indexes")
      .then((response) => {
        // Handle the response data
        setEntre(response.data);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  }
 
  const handleDeleteClick = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Fournis?"
    );
    if (confirmDelete) {
      axios
        .delete(`http://localhost:8000/api/sup/${id}`)
        .then((response) => {
          fetchEntres();
        })
        .catch((error) => {
          console.error("Error deleting Fournis", error);
        });
    }
  };
  const handleEditClick = (id) => {
    // Set the selected fournisseur ID
    const editingEntre = entres.find(
      (entre) => entre.id === id
    );
    console.log(editingEntre); 
    setEditItem(id);
    setEditProduct({
      nom: editingEntre.nom,
    unite: editingEntre.unite,
    quantite: editingEntre.quantite,
    prix_Unitaire: editingEntre.prix_Unitaire,
    photo_Produits: editingEntre.photo_Produits,
    fournisseur: editingEntre.fournisseur,
    categorie: editingEntre.categorie,
    entrepots: editingEntre.entrepots,
    date_create: editingEntre.date_create,
    date_exp: editingEntre,
    });
    setShowEditModal(true);
  };
  const handleEdit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nom", EditInput.nom);
    formData.append("photo_Produits", EditInput.photo_Produits);
    formData.append("unite",EditInput.unite);
    formData.append("quantite",EditInput.quantite);
    formData.append("prix_Unitaire", EditInput.prix_Unitaire);
    formData.append("fournisseur", EditInput.fournisseur);
    formData.append("categorie",EditInput.categorie);
    formData.append("entrepots",EditInput.entrepots);
    formData.append("date_create", EditInput.date_create);
    formData.append("date_exp",EditInput.date_exp);

    axios
      .put(`http://localhost:8000/api/up/${editItem}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          fetchEntres();
          setShowEditModal(false);
        } else {
          //
        }
      })
      .catch((error) => {
        console.error("An error occured", error);
      });
  };

  return (
    <div >
       < NavbarDark />
       <div className="flex"> 
    < DefaultSidebar /> 
    <Card className="w-full lg:w-[calc(100vw-10rem)] p-4">
      <div className="flex items-center justify-between mb-4">
        <div className="relative flex gap-2">
          <Input
            type="search"
            color="dark"
            label="Type here ..."
            className="pr-20"
            containerProps={{
              className: "min-w-[288px]",
            }}
          />
        <Button size="sm" color="blue" className="rounded">
  Filter
</Button>

        </div>
       
        <Article openModal={openModal} />
      </div>
      <div className="overflow-x-auto">
      <table className="w-full min-w-max table-auto text-left  bg-white">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-10 bg-blue-gray-50 p-2"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-50"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {entres.map(
            (
             entre,
              index
            ) => (
              <tr key={index} className={index % 2 === 0 ? "even:bg-blue-gray-50/50" : ""}>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {entre.nom}
                  </Typography>
                </td>
                <td className="p-4">
  <Typography variant="small" color="blue-gray" className="font-normal">

  <img src={entre.photo_Produits} alt="image" width="50" />

  </Typography>
</td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {entre.unite}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {entre.quantite}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {entre.prix_Unitaire}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {entre.fournisseur}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {entre.categorie}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {entre.entrepots}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {entre.date_create}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {entre.date_exp}
                  </Typography>
                </td>
           <td className="p-4">
  <div className="flex items-center">
    <Typography as="button" variant="small" color="blue-gray" className="font-medium"  onClick={() => handleEditClick(entre.id)}>
      <PencilIcon className="h-4 w-4 text-green-500" /> {/* Set color to green */}
    </Typography>

    <Typography as="button" variant="small" color="blue-gray" className="font-medium ml-2" on  onClick={() => handleDeleteClick(entre.id)}>
      <TrashIcon className="h-4 w-4 text-red-500" /> {/* Set color to red */}
    </Typography>
  </div>

  <div>
    
  {showEditModal && ( <EditModal
  product={EditInput} // Pass the EditInput data to the modal
  editItem={editItem}
  showEditModal={showEditModal}
 
  fetchEntres={fetchEntres}
 
   handelFormSubmit={handleEdit}
  onclose={() => {
    setShowEditModal(false);
    setEditItem(null);
    setEditProduct({
    nom: "",
    unite: "",
    quantite: "",
    prix_Unitaire: "",
    photo_Produits: "",
    fournisseur: "",
    categorie: "",
    entrepots: "",
    date_create: "",
    date_exp: "",
    })
    
  }}
/>
)}
  </div>
</td>


               
               
              </tr>
            )
          )}
        </tbody>
      </table>
      <div className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page 1 of 10
        </Typography>
        <div className="flex gap-2">
          <Button variant="outlined" size="sm">
            <ArrowNarrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button variant="outlined" size="sm">
            <ArrowNarrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
      </div>
    </Card>
    </div>
    </div>
   
  );
}
export default Entre;
 