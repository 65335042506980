import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import { BsSearch } from 'react-icons/bs';
import "../css/market.css";
import Footer from '../Inc/footer.jsx';

const List = () => {
  const data = [
    {
      Nom: "John Michael",
      Prenom: "Judicia",
      age: 5,
      Sexe: "feminin",
      photo: "/images/Potter.jpg",
    },
    {
      Nom: "John Michael",
      Prenom: "Judicia",
      age: 5,
      Sexe: "feminin",
      photo: "/images/Julie.jpg",
    },
    {
      Nom: "John Michael",
      Prenom: "Judicia",
      age: 5,
      Sexe: "feminin",
      photo: "/images/Jack.jpg",
    },
    {
      Nom: "John Michael",
      Prenom: "Judicia",
      age: 5,

      photo: "/images/hary.jpg",
    },
    {
      Nom: "John Michael",
      Prenom: "Judicia",
      age: 5,
      Sexe: "feminin",
      photo: "/images/Potter.jpg",
    },
    {
      Nom: "John Michael",
      Prenom: "Judicia",
      age: 5,
      Sexe: "feminin",
      photo: "/images/Julie.jpg",
    },
    {
      Nom: "John Michael",
      Prenom: "Judicia",
      age: 5,
      Sexe: "feminin",
      photo: "/images/Jack.jpg",
    },
    {
      Nom: "John Michael",
      Prenom: "Judicia",
      age: 5,
      overflowY: "hidden",

      photo: "/images/hary.jpg",
    },
  ];
  return (
    <>
      <header className="header-top-strip py-3">
        <div className="container-xxl">
          <div className="row">
            <div className=' d-flex justify-center align-items-center'>

              <h4 className='text-white mb-0'><b>MarketPlace</b></h4>
            
            </div>
          
          </div>
        </div>
      </header>
      <header className='header-upper py-2'>
        <div className="container-xxl">
          <div className="row justify-content-between align-items-center">
             
            </div>
            <div className='col-4 d-flex justify-content-end align-items-center'>
             
            </div>
          </div>
        
      </header>
      
      <header className='header-bottom py-3'>
        <div className='container-xxl'>
          <div className='row'>
           
              <div className='menu-bottom d-flex align-items-center'>
                <div></div>
                </div>
                <div className='d-flex   gap-5 justify-center'>
                  <NavLink className='text-white align-items-center'>Home</NavLink>
                  <NavLink className='text-white align-items-center'>Our Store</NavLink>
                  <NavLink className='text-white align-items-center'>Blogs</NavLink>
                  <NavLink className='text-white align-items-center'>Contact</NavLink>
              
            <div className="header-upper-links d-flex align-items-center justify-content-between">
               
                
               <div className='float-right'>
                 <Link className=' d-flex align-items-center gap-2 text-white'>
                   <img src='images/shopping-cart.png' style={{ filter: 'invert(100%)', fill: 'white',width:'50px' }} alt="Shopping Cart"></img>
                   <div className='d-flex  flex-column gap-3'>
                     <span className='badge bg-white text-dark'>8</span>
                     <p className='mb-2'>Ar 500</p>
                   </div>
                 </Link>
               </div>
             </div>
             </div>
              </div>
            </div>
      </header>
      <body>
      <div className='col-5 justify-center'>

      <div className='input-group mb-0 py-3 '>
                <input
                  type='text'
                  className='form-control py-2'
                  Placeholder='search Product here...'
                  aria-label='search Product here...'
                  aria-description='basic-addon2'
                />
                <span className='input-group-text p-3' id='basic-addon2'>
                  <BsSearch className='fas-6' />
                </span>
              </div>
              </div>
      <div className="row p-4" style={{ display: "flex" }}>
        {data.map((item, rowIndex) => (
          <div className="col-lg-3 m-" key={rowIndex}>
            <div
              className="shadow p-0 mb-0 bg-light rounded text-black"
              style={{
                width: "80%",
                height: "80%",
              }}
            >
              <img
                src={item.photo}
                className="piz"
                alt="Pizza"
                style={{
                  objectFit: "cover",
                  objectFit: "cover",
                  width: "100%",
                  height: "70%",
                }}
              />
              <div></div>

              <div className="nom  ">
                <p>{item.Nom}</p>
              </div>
              <p className="age ">{item.age}ans</p>
            </div>
          </div>
        ))}
      </div>
    
      </body>
      <footer>
      <Footer />
      </footer>
    </>
  )
}

export default List;
