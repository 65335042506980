import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Chip,
  } from "@material-tailwind/react";
  import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    UserCircleIcon,
    ArrowCircleDownIcon,
    ArrowCircleUpIcon ,
    ClockIcon ,
    TruckIcon,
    
    ClipboardListIcon,
    
  } from "@heroicons/react/solid";
  import "../css/side.css";
  
  import { Link } from 'react-router-dom';
  export function DefaultSidebar() {
    return (
      <Card className="h-[calc(120vh-2rem)] w-full max-w-[15rem] p-0 shadow-xl bg-custom-color rounded-none">
        <List className="flex flex-col justify-center items-center text-center">
          <ListItem color="white">
            <ListItemPrefix>
              <PresentationChartBarIcon className="h-5 w-5 text-white" />
            </ListItemPrefix>
            {/* Use Link component for navigation */}
            <Link to="/" className="text-white">Dashboard</Link>
          </ListItem>
          <ListItem>
            <ListItemPrefix>
              <ClipboardListIcon className="h-5 w-5 text-white" />
            </ListItemPrefix>
            {/* Use Link component for navigation */}
            <Link to="/invent" className="text-white">Inventaire</Link>
          </ListItem>
          <ListItem>
            <ListItemPrefix>
              <ArrowCircleDownIcon className="h-5 w-5 text-white" />
            </ListItemPrefix>
            {/* Use Link component for navigation */}
            <Link to="/Entre" className="text-white">Entrée</Link>
          </ListItem>
          <ListItem>
            <ListItemPrefix>
              <ArrowCircleUpIcon className="h-5 w-5 text-white" />
            </ListItemPrefix>
            {/* Use Link component for navigation */}
            <Link to="/sortie" className="text-white">Sortie</Link>
          </ListItem>
          <ListItem>
            <ListItemPrefix>
              <ClockIcon className="h-5 w-5 text-white" />
            </ListItemPrefix>
            {/* Use Link component for navigation */}
            <Link to="/audit" className="text-white">Historique</Link>
          </ListItem>
          <ListItem>
            <ListItemPrefix>
              <TruckIcon className="h-5 w-5 text-white" />
            </ListItemPrefix>
            {/* Use Link component for navigation */}
            <Link to="/" className="text-white">Fournisseur</Link>
          </ListItem>
          <ListItem>
            <ListItemPrefix>
              <ShoppingBagIcon className="h-5 w-5 text-white" />
            </ListItemPrefix>
            {/* Use Link component for navigation */}
            <Link to="/category" className="text-white">Category</Link>
          </ListItem>
        
          <ListItem>
            <ListItemPrefix>
              <ShoppingBagIcon className="h-5 w-5 text-white" />
            </ListItemPrefix>
            {/* Use Link component for navigation */}
            <Link to="/entrepot" className="text-white">Entrepots</Link>
          </ListItem>
          <ListItem>
            <ListItemPrefix>
              <ShoppingBagIcon className="h-5 w-5 text-white" />
            </ListItemPrefix>
            {/* Use Link component for navigation */}
            <Link to="/market" className="text-white">MarketPlace</Link>
          </ListItem>
        </List>
      </Card>
    );
  }
  export default  DefaultSidebar;