import React, { useEffect, useState } from "react";
import axios from "axios";
import EditModal from "./EditModal";
import {
  Card,
  Typography,
  Button,

  Input,
} from "@material-tailwind/react";
import { NavbarDark } from "../Inc/nav";
import { DefaultSidebar } from "../Inc/SideNav";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  PencilIcon,
  TrashIcon,
 
} from "@heroicons/react/solid";

import AjoutModal from "./AjoutModal";
const TABLE_HEAD = ["Nom", "Prenom", "Adresse", "Actions"];

const TABLE_ROWS = [
  {
    Nom: "John Michael",
    Prenom: "Manager",
    Adresse: "1200",
  },
  {
    Nom: "John Michael",
    Prenom: "Manager",
    Adresse: "1200",
  },
  {
    Nom: "John Michael",
    Prenom: "Manager",
    Adresse: "1200",
  },
  {
    Nom: "John Michael",
    Prenom: "Manager",
    Adresse: "1200",
  },
];

const Fournis = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFournisseurId, setSelectedFournisseurId] = useState(null);
  const [editingMenuId, setEditingMenuId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [EditInput, setEditProduct] = useState({
    Nom: "",
    Prenom: "",
    Adresse: "",
  });
  const openModal = () => {
    setShowModal(true);
  };

  const [fournisseurs, SetFournisseurs] = useState([]);

  useEffect(() => {
    fetchFournisseurs();
  }, []); // Empty dependency array means it runs once when the component mounts

  const fetchFournisseurs = async () => {
    axios
      .get("http://localhost:8000/api/index")
      .then((response) => {
        // Handle the response data
        SetFournisseurs(response.data);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  const handleDeleteClick = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Fournis?"
    );
    if (confirmDelete) {
      axios
        .delete(`http://localhost:8000/api/delete/${id}`)
        .then((response) => {
          fetchFournisseurs();
        })
        .catch((error) => {
          console.error("Error deleting Fournis", error);
        });
    }
  };
  const handleEditClick = (id) => {
    // Set the selected fournisseur ID
    const editingFournis = fournisseurs.find(
      (fournisseur) => fournisseur.id === id
    );
    console.log(editingFournis); 
    setEditItem(id);
    setEditProduct({
      Nom: editingFournis.Nom,
      Prenom: editingFournis.Prenom,
      Adresse: editingFournis.Adresse,
    });
    setShowEditModal(true);
  };
  const handleEdit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Nom", EditInput.Nom);
    formData.append("Prenom", EditInput.Prenom);
    formData.append("Adresse", EditInput.Adresse);

    axios
      .put(`http://localhost:8000/api/update/${editItem}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          fetchFournisseurs();
          setShowEditModal(false);
        } else {
          //
        }
      })
      .catch((error) => {
        console.error("An error occured", error);
      });
  };

  return (
    <div>
      <NavbarDark />
      <div className="flex">
        <DefaultSidebar />
        <Card className="w-full lg:w-[calc(100vw-10rem)] p-4">
          <div className="flex items-center justify-between mb-4">
            <div className="relative flex gap-2">
              <Input
                type="search"
                color="dark"
                label="Type here ..."
                className="pr-20"
                containerProps={{
                  className: "min-w-[288px]",
                }}
              />
              <Button size="sm" color="blue" className="rounded">
                Filter
              </Button>
            </div>
            <AjoutModal
              openModal={openModal}
              fetchFournisseurs={fetchFournisseurs}
            />
          </div>
          <div className="overflow-x-auto">
            <table className="w-full min-w-max table-auto text-left  bg-white">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-blue-gray-10 bg-blue-gray-50 p-2"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-50"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {fournisseurs.map((fournisseur, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "even:bg-blue-gray-50/50" : ""}
                  >
                    <td className="p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {fournisseur.Nom}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {fournisseur.Prenom}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {fournisseur.Adresse}
                      </Typography>
                    </td>

                    <td className="p-4">
                      <div className="flex items-center">
                        <Typography
                          as="button"
                          variant="small"
                          color="blue-gray"
                          className="font-medium"
                          onClick={() => handleEditClick(fournisseur.id)}
                        >
                          <PencilIcon className="h-4 w-4 text-green-500" />
                        </Typography>

                        <Typography
                          as="button"
                          variant="small"
                          color="blue-gray"
                          className="font-medium ml-2"
                          onClick={() => handleDeleteClick(fournisseur.id)}
                        >
                          <TrashIcon className="h-4 w-4 text-red-500" />{" "}
                          {/* Set color to red */}
                        </Typography>
                        <div>
                          {/* ... (other JSX) */}

                         {showEditModal && ( <EditModal
  product={EditInput} // Pass the EditInput data to the modal
  editItem={editItem}
  showEditModal={showEditModal}
 
  fetchFournisseurs={fetchFournisseurs}
 
   handelFormSubmit={handleEdit}
  onclose={() => {
    setShowEditModal(false);
    setEditItem(null);
    setEditProduct({
      Nom:"",
      Prenom:"",
      Adresse:""
    })
    
  }}
/>
)}

                          {/* ... (other JSX) */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex items-center justify-between border-t border-blue-gray-50 p-4">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                Page 1 of 10
              </Typography>
              <div className="flex gap-2">
                <Button variant="outlined" size="sm">
                  <ArrowNarrowLeftIcon className="h-4 w-4" />
                </Button>
                <Button variant="outlined" size="sm">
                  <ArrowNarrowRightIcon className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Fournis;
