
import PlanCard from './PlanCard';
import React, { useState } from "react";
import {
  Navbar,
  Typography,
  IconButton,
  Button,
  Input,
  Card,
} from "@material-tailwind/react";

import {
  MailIcon,
  BellIcon,
  ChevronDownIcon,
  UserIcon,
  LogoutIcon,
  MenuIcon,
} from "@heroicons/react/outline";

const Pricing = () => {
  
  // Define the pricing data for each card
  const plans = [
    {
      title: 'Free',
      price: '$0/month',
      features: ['Feature 1', 'Feature 2', 'Feature 3','Feature 4','Feature 5'],
    },
    {
      title: 'Basic Plan',
      price: '$50000/month',
      features: ['Feature 1', 'Feature 2', 'Feature 3','Feature 4','Feature 5'],
    },
    {
      title: 'Pro Plan',
      price: 'Ar 80000/month',
      features: ['Feature 1', 'Feature 2', 'Feature 3','Feature 4','Feature 5'],
    },
    {
      title: 'Premium Plan',
      price: 'Ar 250000/month',
      features: ['Feature 1', 'Feature 2', 'Feature 3','Feature 4','Feature 5'],
    },
  ];
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
      
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div >
    <Navbar
  variant="gradient"
  bg="#05071E" // Set the background color here
  style={{
    left: "20%",
    width: "150%",
  }}
  className="sticky top-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4"
>

    <div className="flex flex-wrap justify-between gap-y-6 text-black">
{/* Card content */}
<div className="flex justify-between gap-5 md:mr-5">
  <img
    src="./images/stock.jpeg"
    alt="Your Image"
    style={{ width: "50%" }}
    className="w-5 h-10"
  />
 <div className="flex ">
         
        </div>
</div>
      <div className=" flex gap-1 md:mr-4">
        <IconButton variant="text" color="black">
          <MailIcon className="h-6 w-4" />
        </IconButton>
        <IconButton variant="text" color="black">
          <BellIcon className="h-4 w-4" />
        </IconButton>
        <IconButton variant="text" color="black" onClick={toggleDropdown}>
          <ChevronDownIcon className="h-6 w-4" />
        </IconButton>
        {isDropdownOpen && (
          <div className="absolute right-0 mt-10 bg-white border rounded shadow-md">
            <ul className="justify-center">
              <li className="mr-4">
                <div className="flex items-center gap-2">
                  <UserIcon className="h-4 w-4 text-blue-500" />{" "}
                  {/* Adjust icon size and color */}
                  <Typography color="black" className="text-sm">
                    Profile
                  </Typography>{" "}
                  {/* Adjust text size */}
                </div>
              </li>
              <li>
                <div className="flex items-center gap-2">
                  <LogoutIcon className="h-4 w-4 text-red-500" />{" "}
                  {/* Adjust icon size and color */}
                  <Typography color="black" className="text-sm">
                    Logout
                  </Typography>{" "}
                  {/* Adjust text size */}
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  </Navbar>
    <div className="flex-col  bg-white ">
    
      <div className="mb-2 mt-6 text-center">
      <h2 className='text-center'><b>Pricing</b> </h2>
      </div>
      <div className="text-center p-10" style={{ display: 'flex', justifyContent: 'center' }}>
        {plans.map((plan, index) => (
          <PlanCard
            key={index}
            title={plan.title}
            price={plan.price}
            features={plan.features}
            style={{ margin: '0 10px' }}
          />
        ))}
      </div>
    </div>
    </div>
  );
};

export default Pricing;
